import React, { useState } from 'react'
import cn from './authForm.module.css'
import { AdminPanel } from '../index.js';
import { useEffect } from 'react';

const AuthForm = (props) => {

    const [isAuthenticated, setAuth] = useState(false);
    const [usr, setUsr] = useState('')
    const [pswd, setPswd] = useState('')
    const [showError, setShowError] = useState(false)
    const [tab, setTab] = useState('Сериалы')

    useEffect(
        () => {
            let token = localStorage.getItem("token")
            if (token !== null) {
                auth(token)
            }
        }, []
    )

    const changeTab = () => {
        if (tab==='Сериалы') {
            setTab('Программма')
        } else {
            setTab("Сериалы")
        }
    }

    const auth = (token) => {

        let authParams = { 'user': usr, 'password': pswd }

        if (token) {
            authParams['token'] = token
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(authParams)
        };

        return fetch(`http://127.0.0.1:5000/admin/auth`, requestOptions)
            .then(response => {
                return response.json()
            }).then(
                async (data) => {
                    console.log(data)
                    setAuth(data.success)
                    if (data.success) {
                        localStorage.setItem('token', data.token)
                        setShowError(false)
                    } else {
                        setShowError(true)
                    }
                }
            );
    }

    const deauth = () => {
        localStorage.removeItem('token')
        setAuth(false)
        setUsr("")
        setPswd("")
    }

    return (
        <div className={cn.container}>
            <div className={cn.form}>
                {!isAuthenticated ? <><div>Авторизация для администратора</div>
                    <div className={cn.formelement}>
                        <input onChange={(e) => { setUsr(e.target.value) }} placeholder='Логин' />
                    </div>
                    <div className={cn.formelement}>
                        <input onChange={(e) => { setPswd(e.target.value) }} placeholder='Пароль' />
                    </div>
                    <div className={cn.formelement}>
                        <div onClick={() => { auth() }} className={cn.button}>Вход</div>
                    </div>
                </> : <div className={cn.formelement}>
                    <div onClick={() => { deauth() }} className={cn.button}>Разлогин</div>
                    <div onClick={changeTab} className={cn.button}>{tab}</div>
                    {tab === 'Сериалы' ? <AdminPanel /> : <img />}
                </div>}
                {showError && <div>Ошибка авторизации</div>}
            </div>
        </div>
    )
}

export default AuthForm