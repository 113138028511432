import React from 'react'
import cn from './playerButton.module.css'
import {Link} from 'react-router-dom'

const PlayerButton = (props) => {
    return (
        <div className={cn.button}>
        <Link to={props.link} style={{ textDecoration: 'none', color:'white'}}>
            <div className={cn.label}>
                {props.children}
            </div>
            </Link>
        </div>
    )
}

export default PlayerButton