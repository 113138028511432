import React, { useState, useEffect } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import ReactJWPlayer from 'react-jw-player';
import { PlayerButton } from '../index';
import cn from './player.module.css'
import Viewers from './Viewers'
import NowAfterContainer from './NowAfterContainer'


const Player = (props) => {



    const isItSSL = () => {
        if (window.location.href.includes("https://")) {
            return "https://foxkids-online.ru/hls/test.m3u8"
        } else {
            return "http://foxkids-online.ru/hls/test.m3u8"
        }
    }

    const isItSSLBool = () => {
        return window.location.href.includes("https://")
    }



    return (
        <div className={cn.mainContianer}>
            <div>
            {isItSSLBool()&&
            <div className={cn.warning}>❗❗ Если стрим не работает, попробуй <a href="http://foxkids-online.ru">запасную ссылку</a> ❗❗</div>}
                <div className={cn.navButtons}>
                    <PlayerButton link={'/'}>
                        ЭФИР
                    </PlayerButton>
                    <PlayerButton link={'/about_channel'}>
                        О КАНАЛЕ
                    </PlayerButton>
                    <PlayerButton link={'/series'}>
                        МУЛЬТФИЛЬМЫ
                    </PlayerButton>
                    <PlayerButton link={'/about_project'}>
                        О ПРОЕКТЕ
                    </PlayerButton>
                </div>
                <div className={cn.marqueeContainer}>
                    <Viewers />
                    <marquee
                        className={cn.marqueeLabel}
                        behavior="scroll"
                        direction="left"
                    >СТАРТ ТРАНСЛЯЦИИ РОВНО В 7.00 КАЖДЫЙ ДЕНЬ! ВКЛЮЧАЙ И СМОТРИ FOXKIDS-ONLINE.RU
                    </marquee>
                </div>
                {/* <ReactJWPlayer
                    playerId='playernLYwDnwqQSuR'
                    playerScript="https://cdn.jwplayer.com/libraries/KDPciQut.js"
                    file="http://185.46.8.246:8080/hls/test.m3u8"
                    autostart={false}
                    repeat={false}
                    bufferlength={0}
                /> */}
                <ReactHlsPlayer
                    id='playernLYwDnwqQSuR'
                    src={isItSSL()}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="auto"
                />
                <div className={cn.greyContainer}>
                     <NowAfterContainer /> 
                </div>
            </div>
        </div>
    )
}

export default Player