import React, { useState, useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import cn from './player.module.css'

const Viewers = () => {
    const [viewers, setViewers] = useState("...")
    const isItSSL = () => {
        if (window.location.href.includes("https://")) {
            return "wss://foxkids-online.ru:5001/"
        } else {
            return "ws://foxkids-online.ru:5000/"
        }
    }

    useEffect(() => {
        const socket = socketIOClient(isItSSL());
        socket.on("echo", data => {
            if (data.data.count === 0) {
                setViewers("...")
            } else {
                setViewers(data.data.count)
            }
        });
        
    }, []);

    return (
        <div className={cn.viewers}>👁‍🗨 {viewers}</div>
    )
}

export default Viewers