import React from 'react'
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Snowfall from 'react-snowfall'
import {
  MainContainer,
  Subcontainer,
  Footer,
  Header,
  Player,
  AboutChannel,
  AboutProject,
  SeriesList,
  AuthForm
} from './containers'

function App() {
  return (
    <div className="App">
      
      {/* <Snowfall/> */}
      <MainContainer>
      
        <Subcontainer>
        
        <Router>
          <div className="flex-container between">
            <Header />
            
            <div className="container-red">
                <Routes>
                  <Route path="/" element={<Player/>} />
                  <Route path="/about_channel" element={<AboutChannel/>} />
                  <Route path="/about_project" element={<AboutProject/>} />
                  <Route path="/series" element={<SeriesList/>} />
                  <Route path="/admin/auth" element={<AuthForm/>}/>
                </Routes>
            </div>
            <Footer />
          </div>
          </Router>
        </Subcontainer>
      </MainContainer>
    </div>
  );
}

export default App;
