import React, { useState, useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import cn from './player.module.css'


const SERIES_NAMES = {
    "": "...",
    "denis": "Денис Непоседа",
    "wunshpunsh": "Вунш Пунш",
    "luie": "Жизнь с Луи",
    "andy": "Что с Энди",
    "kids402": "Детки из класса 402",
    "bobby": "Мир с Бобби",
    "flint": "Флинт: Детектив во времени",
    "peter_pan": "Питер Пен",
    "hitkliff": "Хитклифф",
    "marina": "Русалочка",
    "malon": "Уолтер Мэлон",
    "pinoccio": "Пиноккио",
    "twist": "Оливер Твист",
    "monster_farm": "Ферма Чудища",
    "tick": "Тик герой",
    "sissi": "Принцесса Сисси",
    "spydogs": "Секретные материалы псов шпионов",
    "tarakan": "Таракан Робот",
    "rangers": "Пауэр Рейнджерс",
    "spider_man": "Человек-паук",
    "iron_man": "Железный человек",
    "batman": "Бэтмэн",
    "addams": "Семейка Аддамс",
    "indiana": "Мистический городок Эйри в Индиане",
    "pigcity": "Свин-сити",
    "ginger": "Как говорит Джинджер",
    "tomnjerry": "Том и Джерри",
    "diabolik": "Дьяволик",
    "tobikage": "Роботы Ниндзя",
    "snobs": "Собака по имени Снобз",
    "button_nose":"Лили в клубничном мире", 
    "islands":"Пиратские острова", 
    "jim_button":"Джим Баттон",
    "22Z":"Космические агенты",
    "beetleborgs":"Битлборги"
}

const NowAfterContainer = () => {
    const [nowafter, setNowafter] = useState({ "now": "", "after": "" })
    const isItSSL = () => {
        if (window.location.href.includes("https://")) {
            return "wss://foxkids-online.ru:5001/"
        } else {
            return "ws://foxkids-online.ru:5000/"
        }
    }

    useEffect(() => {
        const socket = socketIOClient(isItSSL());
        socket.on("get_promo", data => {
            console.log("get_promo", data)
            setNowafter(data)
        });
    }, []);

    const tryRequire = (path) => {
        try {
            console.log(`../../media/logos/${path}.png`)
         return require(`../../media/logos/${path}.png`);
        } catch (err) {
         return require(`../../media/logos/foxkids.png`);
        }
      };

    return (
        <div className={cn.nowAfterContianer}>
            <div className={cn.nowAfterSub}>
                <div>СЕЙЧАС:</div>
                <div className={cn.nowAfterLabel}>{SERIES_NAMES[nowafter["now"]] ? SERIES_NAMES[nowafter["now"]] : nowafter["now"]}</div>
            </div>
            <img className={cn.nowAfterImg} src={tryRequire(nowafter["now"])} />
            <div className={cn.nowAfterSub}>
                <div>ДАЛЕЕ:</div>
                <div className={cn.nowAfterLabel}>{SERIES_NAMES[nowafter["after"]] ? SERIES_NAMES[nowafter["after"]] : nowafter["after"]}</div>

            </div>
            <img className={cn.nowAfterImg} src={tryRequire(nowafter["after"])} />
        </div>
    )
}

export default NowAfterContainer