import React from 'react'
import cn from './aboutProject.module.css'

const AboutProject = (props) => {
    return (
        <div className={cn.mainContainer}>
            <div>
                <img className={cn.imgObject} src={require('../../media/site/aboutproject.jpg')} />
            </div>
            <div className={cn.labelContainer}>
                <p>WWW.FOXKIDS.ONLINE - НЕКОММЕРЧЕСКИЙ ФАНАТСКИЙ ИНДИ-ПРОЕКТ, ПОСВЯЩЕННЫЙ ОДНОИМЕННОМУ КАНАЛУ. НА САЙТЕ ОРГАНИЗОВАН ЭФИР ЛЮБИМЫХ СЕРИАЛОВ, СПИСОК КОТОРЫХ ПОСТЕПЕННО ПОПОЛНЯЕТСЯ. ПРОГРАММА СОПРОВОЖДАЕТСЯ СТАРЫМИ ДОБРЫМИ АРХИВНЫМИ РЕКЛАМАМИ С ВЕСЕЛОЙ ГРАФИКОЙ.</p>

                <p>БУДЕМ РАДЫ НОВЫМ ЗРИТЕЛЯМ, УЧАСТНИКАМ И ЕДИНОМЫШЛЕННИКАМ В СОЦ. СЕТЯХ:</p>

                <p>TELEGRAM-КАНАЛ: <a href='HTTPS://T.ME/FOXKIDS_ONLINE'>HTTPS://T.ME/FOXKIDS_ONLINE</a></p>
                <p>VK-СТРАНИЦА АВТОРА ПРОЕКТА: <a href='HTTPS://VK.COM/FOXKIDS.ONLINE'>HTTPS://VK.COM/FOXKIDS.ONLINE</a></p>
                <p>YOUTUBE: <a href='HTTPS://WWW.YOUTUBE.COM/CHANNEL/UCGS54FUN3WPU8XN7ZM5VQOA'>HTTPS://WWW.YOUTUBE.COM/CHANNEL/UCGS54FUN3WPU8XN7ZM5VQOA</a></p>
                <p>ЭЛЕКТРОННАЯ ПОЧТА: TV@FOXKIDS.ONLINE</p>
            </div>
        </div>
    )
}

export default AboutProject