import React from 'react'
import cn from './header.module.css'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import HeaderLink from './HeaderLink.js'

const Header = (props) => {
    return (
        <div className={cn.headerContainer}>
            <img className={cn.flippersImg} src={require('../../media/site/flippers.png')} />
            <div className={cn.navContainer}>
                    <HeaderLink link="/">
                        <div className={cn.navItem}>ЭФИР</div>
                    </HeaderLink>
                    <HeaderLink link="/about_channel">
                        <div className={cn.navItem}>О КАНАЛЕ</div>
                    </HeaderLink>
                    <HeaderLink link="/series">
                        <div className={cn.navItem}>МУЛЬТФИЛЬМЫ</div>
                    </HeaderLink>
                    <HeaderLink link="/about_project">
                        <div className={cn.navItem}>О ПРОЕКТЕ</div>
                    </HeaderLink>
            </div>
        </div>
    )
}

export default Header