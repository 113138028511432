import React, { useState } from 'react'
import { useEffect } from 'react'
import cn from './adminpanel.module.css'

const AdminPanel = (props) => {

    const [series, setSeries] = useState([])
    const [error, showError] = useState(false)
    const [success, showSuccess] = useState(false)

    useEffect(() => {
        let token = localStorage.getItem('token')
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'token': token },
        };

        return fetch(`http://127.0.0.1:5000/admin/get-series`, requestOptions)
            .then(response => {
                return response.json()
            }).then(
                async (data) => {
                    console.log(data)
                    setSeries(data)
                }
            );
    }, [])

    const deleteSerial = (name) => {
        console.log(name)
        console.log(series)
        setSeries(oldValues => {
            return oldValues.filter((x, i) => x.name !== name)
        })
    }

    const currentSeriaChange = (e, index) => {
        let newSeries = [...series]
        newSeries[index].current_seria = parseInt(e.target.value)
        setSeries(newSeries)
        console.log(series)
    }

    const countChange = (e, index) => {
        let newSeries = [...series]
        newSeries[index].count = parseInt(e.target.value)
        setSeries(newSeries)
        console.log(series)
    }

    const nameChange = (e, index) => {
        let newSeries = [...series]
        newSeries[index].name = e.target.value
        setSeries(newSeries)
        console.log(series)
    }

    const updateSeries = () => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem("token") },
            body: JSON.stringify(series)
        };

        return fetch(`http://127.0.0.1:5000/admin/set-series`, requestOptions)
            .then(response => {
                return response.json()
            }).then(
                async (data) => {
                    console.log(data)
                    setSeries(data)
                    showSuccess(true)
                }
            ).catch(
                ()=>{showError(true)}
            );
    }

    const addNewSeria = () => {
        let newSeries = [...series]
        let mock = {
            "name": "",
            "count": 0,
            "current_seria": 0
        }
        newSeries.push(mock)
        setSeries(newSeries)
        console.log(series)
    }

    return (
        <div >
            <div >{
                series.map((item, index) => {
                    return <div key={item.name+"sera"} className={cn.serialCard}>
                        <div className={cn.cardHeader}>
                            <div onClick={() => { deleteSerial(item.name) }}
                                className={cn.deleteSerialIcon}>x</div>
                            <input onBlur={(e) => { nameChange(e, index) }} defaultValue={item.name} />
                        </div>
                        <input type='number' onChange={(e) => { currentSeriaChange(e, index) }} defaultValue={item.current_seria} />
                        <input type='number' onChange={(e) => { countChange(e, index) }} defaultValue={item.count} /></div>
                })}
                <div style={{marginBottom:8}} onClick={addNewSeria} className={cn.button} >Добавить</div>
                <div onClick={updateSeries} className={cn.button}>Сохранить</div>
                {error&&<div style={{color:'red'}}>Не успешно<div onClick={()=>{showError(false)}} className={cn.deleteSerialIcon}>x</div></div>}
                {success&&<div style={{color:'green'}}>Успешно<div onClick={()=>{showSuccess(false)}} className={cn.deleteSerialIcon}>x</div></div>}
            </div>

        </div>
    )
}

export default AdminPanel