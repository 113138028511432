import cn from './donate.module.css'
const QuiwiDonate = () => {
    return (
        <div className={cn.donate}>
        <div>
        <img width={100} src={require('../../media/site/foxlogo.png')}></img>
        <img width={150} src={require('../../media/site/heart.png')}></img>
            <p>Проект foxkids-online.ru был задуман для того, чтобы дарить зрителям нотки ностальгии по детству и хорошее настроение.</p>
            <p>Каждый месяц я плачу 400р. за vps-сервер и 3000р. раз в год за облако для любимых серий. </p>
            <p>Если ты хочешь, то ты можешь помочь проекту, сделав небольшое пожертвование, и тоже поднять нам настроение.</p>
            <p>Мы транслируемся с любовью.</p>
        </div>
        <div>
            <iframe src="https://widget.qiwi.com/widgets/big-widget-728x200?publicKey=48e7qUxn9T7RyYE1MVZswX1FRSbE6iyCj2gCRwwF3Dnh5XrasNTx3BGPiMsyXQFNKQhvukniQG8RTVhYm3iPzoSFCyJS5MnBSqvVQ371erKtcy9WG1YxSZUAocrLBanPyEZSEdDJmVWbmzLSkuAmy3GYSY3BuJvNiiy9ahJbCwzCipuiPVQpKPe13v9UM&noCache=true" width="728" height="200"allowTransparency="true" scrolling="no" frameBorder="0"></iframe>
        </div>
        </div>
    )
}

export default QuiwiDonate