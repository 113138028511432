import React from 'react'
import cn from './mainContainer.module.css'
const MainContainer = (props) => {
    return (
        <div className={cn.mainContainer}>
            {props.children}
        </div>
    )
}

export default MainContainer;