import React from 'react'
import cn from './aboutChannel.module.css'

const AboutChannel = (props) => {
    return (
        <div className={cn.mainContainer}>
            <div>
                <img className={cn.imgObject} src={require('../../media/site/foxkids.jpeg')} />
            </div>
            <div className={cn.labelContainer}>
                <p>FOX KIDS - ТЕЛЕВЕЗИОННЫЙ КАНАЛ С ДЕТСКИМИ АМЕРИКАНСКИМИ И КАНАДСКИМИ МУЛЬТФИЛЬМАМИ И СЕРИАЛАМИ, ОСНОВАННЫЙ В 1990 ГОДУ. ОСНОВАТЕЛЬ КАНАЛА - ХАИМ САБАН.</p>

                <p>В РОССИИ ТРАНСЛИРОВАЛСЯ С 1999 ПО 2005 ГОД КАК САМОСТОЯТЕЛЬНЫЙ КАНАЛ, А ТАКЖЕ КАК ПРОГРАММНЫЙ БЛОК НА КАНАЛЕ REN TV. НЕКОТОРЫЕ СЕРИАЛЫ ТРАНСЛИРОВАЛИСЬ ОТДЕЛЬНО НА ТАКИХ КАНАЛАХ КАК СТС, ПЕРВЫЙ, НТВ. ДУБЛЯЖ ЗАПИСЕЙ, ПЕРЕДАННЫХ НА КАССЕТАХ ИЗ ЛОНДОНА, ОТКУДА ВЕЛОСЬ ВЕЩАНИЕ, ПРОИЗВОДИЛСЯ КОМПАНИЕЙ "INTRA COMMUNICATIONS". В РОССИИ КАНАЛ ТРАНСЛИРОВАЛСЯ ПО СПУТНИКОВЫМ И КАБЕЛЬНЫМ СЕТЯМ.</p>

                <p>ПОЗЖЕ БЫЛ ПРОДАН КОМПАНИИ DISNEY, В 2005 БЫЛ ПЕРЕИМЕНОВАН ВО ВРЕМЕННЫЙ КАНАЛ JETIX В РАМКАХ РЕБРЕНДИНГА КОМПАНИИ.</p>
            </div>
        </div>
    )
}

export default AboutChannel