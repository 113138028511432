import cn from './seriesList.module.css'

const SeriesList = (props) => {
    const series = [
        {
            "name": "СВИН-СИСТИ",
            "year": "2002",
            "genre": "(КОМЕДИЯ)",
            "description": "ДЕРЕВЕНСКИЙ СВИН МАРТИ ПЕРЕБИРАЕТСЯ В ШКОЛУ ГОРОДА СВИН СИТИ К РОДСТВЕННИКАМ. ЗДЕСЬ СО СВОИМИ КУЗЕНАМИ МАРТОЙ И РЕДЖИ ОНИ ВЛИПАЮТ В ПЕРЕДЕЛКИ И КОНЕЧНО ЖЕ НАХОДЯТ ИЗ НИХ ВЫХОД.",
            "img": "pigcity.jfif"
        },
        {
            "name": "ИНСПЕТКОР ГАДЖЕТ",
            "year": "1983",
            "genre": "(КОМЕДИЯ, ПРИКЛЮЧЕНИЯ, ДЕТЕКТИВ)",
            "description": "ДЕТЕКТИВНЫЙ ДЕТСКИЙ МУЛЬТФИЛЬМ ПРО НАПИЧКАННОГО РАЗЛИЧНЫМИ УСТРОЙСТВАМИ НЕУКЛЮЖЕГО ИНСПЕКТОРА ПОЛИЦИИ. В РАССЛЕДОВАНИЯХ ПРИНИМАЕТ УЧАСТИЕ ЕГО ПЛЕМЯННИЦА ПЕННИ, ПЕС БРАЙЕН, А ГЛАВНЫМ ЗЛОДЕЕМ СЕРИЙ ВЫСТУПАЕТ ТАИНСТВЕННЫЙ И НЕУЛОВИМЫЙ ДОКТОР КЛО!",
            "img": "gadget.jfif"
        },
        {
            "name": "ДЕТКИ ИЗ КЛАССА 402",
            "year": "2000",
            "genre": "(КОМЕДИЯ)",
            "description": "СЕРИАЛ ДО МОЗГА КОСТЕЙ ПРОПИТАН ШКОЛЬНЫМ БЫТОМ АМЕРИКАНСКОЙ НАЧАЛЬНОЙ ШКОЛЫ. ДЕТКИ ИЗ КЛАССА 402 ПОСТОЯННО ШАЛЯТ, ХУЛИГАНЯТ И РАЗВЕКАЮТСЯ ВО ВСЮ. КРОМЕ ТОГО КАЖДАЯ СЕРИЯ РАДУЕТ ЯРКОЙ АТМОСФЕРНОЙ РИСОВКОЙ И МУЗЫКОЙ.",
            "img": "402.jfif"
        },
        {
            "name": "ХИТКЛИФФ",
            "year": "1984",
            "genre": "(КОМЕДИЯ, ПРИКЛЮЧЕНИЯ)",
            "description": "СЕРИАЛ ПОВЕДАЕТ О ПРИКЛЮЧЕНИЯХ НЕУГОМОННОГО РЫЖЕГО КОТА ХИТКЛИФФА И ЕГО УЛИЧНЫХ ПОХОЖДЕНИЯХ. РАЗБОРКИ УЛИЧНЫХ БАНД КОТОВ, РАЗБИТЫЙ КАДИЛЛАК И КОНЕЧНО ЖЕ ЛЮБОВЬ К КОШЕЧКЕ СОНЕ - ЭТО ВСЯ ЖИЗНЬ УСАТОГО ХУЛИГАНА.",
            "img": "hitkliff.jfif"
        },
        {
            "name": "ДЕНИС НЕПОСЕДА",
            "year": "1986",
            "genre": "(КОМЕДИЯ, СЕМЕЙНЫЙ)",
            "description": "ВЕСЕЛЫЙ МУЛЬТСЕРИАЛ ПО МОТИВАМ ОДНОИМЕННОГО АМЕРИКАНСКОГО КОМИКСА О СЧАСТЛИВОМ ДЕТСТВЕ МАЛЬЧИШКИ ДЕНИСА. БЕСКОНЕЧНЫЕ ШАЛОСТИ, ИГРЫ С ДРУЗЬЯМИ И КОНЕЧНО ДРУЖБА СО СТАРЫМ ДОБРЫМ МИСТЕРОМ УИЛСОНОМ НЕ ДАДУТ ВАМ ЗАСКУЧАТЬ!",
            "img": "denis.jpg"
        },
        {
            "name": "МИР БОББИ",
            "year": "1990",
            "genre": "(КОМЕДИЯ, СЕМЕЙНЫЙ)",
            "description": "СЕМЕЙНЫЙ КОМЕДИЙНЫЙ МУЛЬТСЕРИАЛ О МАЛЫШЕ ПО ИМЕНИ БОББИ С БОГАТЫМ ВООБРАЖЕНИЕМ. ВСЕ ЧТО ЕМУ НЕПОНЯТНО, БОББИ ВОСПРИНИМАЕТ В СВОЕМ ВОЛШЕБНОМ МИРЕ ФАНТАЗИЙ, В КОТОРОМ ЕГО КАЖДЫЙ ДЕНЬ ЖДУТ НОВЫЕ ПРИКЛЮЧЕНИЯ И В КОТОРОМ МОЖНО СТАТЬ КЕМ УГОДНО!",
            "img": "bobby.jfif"
        },
        {
            "name": "КОТ ИК",
            "year": "1994",
            "genre": "(КОМЕДИЯ)",
            "description": "КОМЕДИЙНЫЙ СЕРИАЛ О ПРИКЛЮЧЕНИЯХ ФИОЛЕТОВОГО КОТА ПО ИМЕНИ ИК.",
            "img": "eak.jpg"
        },
        {
            "name": "ЖИЗНЬ С ЛУИ",
            "year": "1994",
            "genre": "(КОМЕДИЯ, СЕМЕЙНЫЙ)",
            "description": "ДОБРЫЙ, СЕМЕЙНЫЙ МУЛЬТСЕРИАЛ РАССКАЖЕТ О БУДНЯХ ДЕСТВА ВЕСЕЛОГО АМЕРИКАНСКОГО КОМИКА ЛУИ АНДЕРСОНА.",
            "img": "luie.jpg"
        },
        {
            "name": "БОЛЬШИЕ И КРУТЫЕ БИТЛБОРГИ",
            "year": "1996",
            "genre": "(КОМЕДИЯ, БОЕВИК, ПРИКЛЮЧЕНИЯ)",
            "description": "ТРОЕ ШКОЛЬНЫХ ДРУЗЕЙ ЗАЛЕЗАЮТ В ДОМ ПРИВЕДЕНИЙ, ГДЕ ЗАБАВНЫЙ ПОЛТЕРГЕЙСТ И ПРИЗРАК ЭЛВИСА ПРЕСЛИ ПО ИМЕНИ ФЛАББЕР ИСПОЛНЯЕТ ИХ САМОЕ ЗАВЕТНОЕ ЖЕЛАНИЕ - СТАТЬ СУПЕРГЕРОЯМИ ИЗ ПОПУЛЯРНЫХ КОМИКСОВ - БИТЛБОРГАМИ.",
            "img": "beetleborgs.jpg"
        },
        {
            "name": "ЛИЛИ В КЛУБНИЧНОМ МИРЕ",
            "year": "1985",
            "genre": "(АНИМЕ, ПРИКЛЮЧЕНИЯ, СЕМЕЙНЫЙ)",
            "description": "СЕРИАЛ О ПРИКЛЮЧЕНИЯХ МАЛЕНЬКОЙ ДЕВОЧКИ ПУГОВКИ, КОТОРАЯ ПРОВОДИТ ЛЕТНИЕ КАНИКУЛЫ У ДЯДИ , КОРОЛЯ ВОЛШЕБНОЙ КЛУБНИЧНОЙ СТРАНЫ.",
            "img": "lily.jpg"
        },
        {
            "name": "ПИТЕР ПЭН",
            "year": "1989",
            "genre": "(АНИМЕ, ПРИКЛЮЧЕНИЯ, СЕМЕЙНЫЙ)",
            "description": "АНИМЕ-СЕРИАЛ ПО МОТИВАМ ОДНОИМЕННОЙ СКАЗКИ ДЖ. БАРРИ ИЗ ЦИКЛА \"ТЕАТР МИРОВЫХ ШЕДЕВРОВ\".",
            "img": "pan.jpg"
        },
        {
            "name": "ПИНОККИО",
            "year": "1972",
            "genre": "(АНИМЕ, ДРАМА, СЕМЕЙНЫЙ)",
            "description": "АНИМЕ-СЕРИАЛ ПО МОТИВАМ ОДНОИМЕННОЙ СКАЗКИ К. КОЛЛОДИ.",
            "img": "pinoccio.jpg"
        },
        {
            "name": "БЕШЕНЫЙ ДЖЕК ПИРАТ",
            "year": "1998",
            "genre": "(КОМЕДИЯ, ПРИКЛЮЧЕНИЯ)",
            "description": "ВЕСЕЛАЯ КОМЕДИЯ О ПРИКЛЮЧЕНИЯХ НЕУДАЧЛИВОГО НЕГОДЯЯ БЕШЕНОГО ДЖЕКА ПИРАТА И ЕГО СТАРШЕГО ПОМОЩНИКА КОРАБЕЛЬНОЙ КРЫСЫ СНАКА.",
            "img": "pirate.jfif"
        },
        {
            "name": "ТИК ГЕРОЙ",
            "year": "1994",
            "genre": "(КОМЕДИЯ, СУПЕРГЕРОИ)",
            "description": "АМЕРИКАНСКИЙ МУЛЬТСЕРИАЛ В ЖАНРЕ СУПЕРГЕРОЙСКОЙ ПАРОДИИ. ГЛАВНЫЙ ГЕРОЙ ТИК И ЕГО ПОМОЩНИК АРТУР БОРЯТСЯ С ПРЕСТУПНОСТЬЮ В ЛИЦЕ СУПЕРЗЛОДЕЕВ, ОРУДУЮЩИХ В ГОРОДЕ (THE CITY).",
            "img": "tick.jpg"
        },
        {
            "name": "СЕКРЕТНЫЕ МАТЕРИАЛЫ ПСОВ ШПИОНОВ",
            "year": "1999",
            "genre": "(КОМЕДИЯ, ДЕТЕКТИВ)",
            "description": "ПОЧЕМУ- ТО СЧИТАЕТСЯ, ЧТО СОБАКИ - ЛУЧШИЕ ДРУЗЬЯ ЧЕЛОВЕКА. НО ЧТО МЫ НА САМОМ ДЕЛЕ О НИХ ЗНАЕМ? ВСЕ СОБАКИ ВХОДЯТ В ТАЙНУЮ ОРГАНИЗАЦИЮ, ПРИЗВАННУЮ СПАСАТЬ ЛЮДЕЙ ОТ ВСЯЧЕСКИХ НАПАСТЕЙ!",
            "img": "spydogs.jpg"
        },
        {
            "name": "ЧЕЛОВЕК-ПАУК",
            "year": "1994",
            "genre": "(СУПЕРГЕРОИ, ДРАМА, ТРИЛЛЕР)",
            "description": "КУЛЬТОВЫЙ СЕРИАЛ О БЕССТРАШНОМ ГЕРОЕ В МАСКЕ.",
            "img": "spiderman.png"
        },
        {
            "name": "ИКСМЕНЫ",
            "year": "1992",
            "genre": "(СУПЕРГЕРОИ, ДРАМА, ТРИЛЛЕР)",
            "description": "СЕРИАЛ ПОВЕСТВУЕТ О КОМАНДЕ СВЕРХЛЮДЕЙ, ЖИВУЩИХ В НЕДАЛЕКОМ БУДУЩЕМ И ЗАЩИЩАЮЩЕЕ ЧЕЛОВЕЧЕСТВО ОТ ГЕНЕТИЧЕСКОЙ ВОЙНЫ.",
            "img": "xmen.jpeg"
        },
        {
            "name": "КОМАНДА 22Z: КОСМИЧЕСКИЕ АГЕНТЫ",
            "year": "1996",
            "genre": "(ТРИЛЛЕР, СВЕРХЪЕСТЕСТВЕННОЕ)",
            "description": "СЕРИАЛ О КОМАНДЕ СУПЕРСОЛДАТ, РАССЛЕДУЮЩИХ СЛУЧАИ ВТОРЖЕНИЯ НА ЗЕМЛЮ ПРИШЕЛЬЦЕВ ИЗ КОСМОСА.",
            "img": "22z.jfif"
        },
        {
            "name": "МУРАШКИ",
            "year": "1992",
            "genre": "(УЖАСЫ, СВЕРХЪЕСТВЕСТВЕННОЕ)",
            "description": "МОЛОДЕЖНЫЙ СЕРИАЛ ПО МОТИВАМ КНИГ РОБЕРТА СТАЙНА.",
            "img": "goosehumbs.jpg"
        },
        {
            "name": "МИСТИЧЕСКИЙ ГОРОДОК ЭЙРИ В ИНДИАНЕ",
            "year": "1991",
            "genre": "(УЖАСЫ, СВЕРХЪЕСТВЕСТВЕННОЕ)",
            "description": "ЭЙРИ ШТАТ ИНДИАНА, НАСЕЛЕНИЕ 16 661 ЧЕЛОВЕК. ПЕРЕЕХАВШИЙ СЮДА МАЛЬЧИШКА МАРШАЛ ТАЙЛЕР И ЕГО ДРУГ САЙМОН ХОЛМС НАЧИНАЮТ ЗАМЕЧАТЬ ПРОИСХОДЯЩИЕ В ГОРОДКЕ СТРАННЫЕ И ПАРАНОРМАЛЬНЫЕ ВЕЩИ.",
            "img": "eire.jpg"
        },
    ]
    return (
        <div>
            {
                series.map((item, index) => {
                    let { name, year, genre, description, img } = item
                    return (
                        <div className={cn.mainContianer}>
                            <div className={cn.labelContainer}>
                                <div>{name}</div>
                                <div className={cn.yearGenre}><div>{year}</div>|<div>{genre}</div></div>
                                <div>{description}</div>
                            </div>
                            <div>
                                <img className={cn.logo} src={require(`../../media/series/${img}`)} />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SeriesList